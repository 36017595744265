<template>
  <div class="topic_Warp">
    <div class="topic"
         ref="topicRef">
      <div class="header">
        <div class="title">
          <span class="yuan"
                v-if="!$route.query.isErr">{{topic.paper_question_no}}</span>
          题目编码:{{topic.question_code}} <span class="fenshu">题目分数：</span> <span style="color:#FF644A">{{topic.question_score}}</span>
        </div>
        <div class="collect">
          <span>
            <el-tooltip class="item"
                        effect="dark"
                        content="纠错"
                        placement="top">
              <img src="@/assets/icons/jiucuo.png"
                   style="margin-right:15px"
                   v-if="!$route.query.isErr"
                   @click="jiucuo"
                   alt="">
            </el-tooltip>
          </span>
          <span v-if="topic.if_in_favorite == 0">
            <el-tooltip class="item"
                        effect="dark"
                        content="添加到我的收藏"
                        placement="top">
              <img src="@/assets/checkTestPage/icon-wsc@2x (1).png"
                   style="margin-right:15px"
                   @click="collect"
                   alt="">
            </el-tooltip>
          </span>
          <span v-else-if="topic.if_in_favorite == 1">
            <el-tooltip class="item"
                        effect="dark"
                        content="已收藏"
                        style="margin-right:15px"
                        placement="top">
              <img src="@/assets/checkTestPage/icon-yi@2x.png"
                   alt="">
            </el-tooltip>
          </span>
          <span v-if="topic.if_all_in_favorite == 0">
            <el-tooltip class="item"
                        effect="dark"
                        content="添加本道题到我的收藏"
                        placement="top">
              <img src="@/assets/checkTestPage/icon-wsc.png"
                   @click="collect"
                   v-if="topic"
                   alt="" />
            </el-tooltip>
          </span>
          <span v-else-if="topic.if_all_in_favorite == 1">
            <el-tooltip class="item"
                        effect="dark"
                        content="整道大题已收藏"
                        placement="top">
              <img src="@/assets/checkTestPage/icon-yio@2x.png"
                   alt="">

            </el-tooltip>
          </span>
        </div>
      </div>
      <div class="content"
           v-if="topic.question_id">
        <div class="neirong">
          <span class="left_head">题目ID：{{topic.question_id}}</span>
        </div>
      </div>

      <div class="content"
           v-if="topic.question_head_content">
        <div class="neirong">
          <span class="left_head">题头：</span>
        </div>
        <div class="warp">
          <div style="padding:5px"
               v-html="topic.question_head_content">

          </div>
        </div>
        <div class="bottom_border"></div>
      </div>
      <div class="content">
        <div class="neirong">
          <span class="left_head">题目内容：</span>
        </div>
        <div class="warp"
             v-html="$replaceAll(topic.question_content)"></div>
        <div class="bottom_border"></div>

      </div>
      <div class="content">
        <div class="neirong">
          <span class="left_head">题目答案：</span>
        </div>
        <div class="warp"
             style="color:#FF6147"
             v-html="$replaceAll(topic.question_answer)"></div>
        <div class="bottom_border"></div>

      </div>
      <div class="content">
        <div class="neirong">
          <span class="left_head">题目解释:</span>
        </div>
        <div class="warp"
             v-html="$replaceAll(topic.question_explain)">
        </div>
        <div class="bottom_border"></div>

      </div>
    </div>
    <div class="evaluate">

      <div class="zhishidian"
           v-if=" topic.knowledgePointAndScore && topic.knowledgePointAndScore.length>1">
        <div class="title">
          知识点
        </div>
        <div class="item"
             v-for="(item,index) in topic.knowledgePointAndScore"
             :key="index">
          <span>
            {{item.knowledge_desc}}({{item.knowledge_score}})
          </span>
          <span>
            <el-input-number v-model="item.score"
                             :min="0"
                             style="width:80%;height:15.25%"
                             :disabled="$route.query.isCheck==1"
                             :max="Number(item.knowledge_score)" />
          </span>
        </div>
      </div>
      <div class="defen_warp">
        <span class="span_inline_block">得分</span>
        <el-input-number v-model="user_score"
                         :min="0"
                         style="width:27%;height:15.25%"
                         :disabled="$route.query.isCheck==1||(topic.knowledgePointAndScore && topic.knowledgePointAndScore.length>1)"
                         :max="number"
                         label="得分">
        </el-input-number>

        <div class="submit_warp"
             v-if="$route.query.isCheck!=1 && !$route.query.isErr">
          <div class="text_btn_primary"
               style="paddin-right:0"
               v-if="topic.is_ignored != 0"
               @click="submitTopic('')">重新评分
          </div>

          <div v-if="!topic.is_ignored"
               style="paddin-right:0"
               class="text_btn_primary"
               @click="submitTopic('')">
            提交单题
          </div>

          <div class="text_btn_danger"
               v-if="!topic.is_ignored"
               style="color:#FF6147;paddin-right:0"
               @click="submitTopic('ignore')">忽略</div>
        </div>
      </div>

      <div class="pingjia_warp">
        <span class="span_inline_block">评价</span>
        <el-input placeholder="评价"
                  type="textarea"
                  :autosize="{minRows:2,maxRows:5}"
                  :disabled="$route.query.isCheck==1"
                  style="width:78%;height:15.25%"
                  v-model="topic.comment_to_user" />
      </div>
      <div class="bottom_btn_warp"
           v-if="!$route.query.isErr">
        <div class="upStage"
             @click="sub">
          <i class="el-icon-arrow-left"></i>
        </div>
        <div class="btn"
             @click="add">
          下一题
        </div>

        <div class="btn submit_all"
             v-if="$route.query.isCheck!=1"
             @click="submitTopic('done')">
          提交整个试卷
        </div>
      </div>

      <div class="bottom_btn_warp"
           v-else>
        <div class="btn"
             @click="editUserTopic">
          提交
        </div>
      </div>

    </div>
    <jiucuo ref="jiucuoRef" />
    <Form ref="FormRef" />
    <evaluate ref="evaluateRef" />
  </div>
</template>

<script>
import Form from './components/index.vue'
import evaluate from './components/evaluate.vue'
import jiucuo from '@/components/jiucuo.vue'
export default {
  data () {
    return {
      topicList: [],
      index: 0,
      user_score: 0
    }
  },
  components: {
    Form, evaluate, jiucuo
  },
  computed: {
    number () {
      return Number(this.topic.question_score)
    }
  },
  mounted () {
    this.user_score = this.topic.user_score || 0
  },
  watch: {
    topic: {
      handler () {
        if (this.topic.knowledgePointAndScore && this.topic.knowledgePointAndScore.length > 1) {
          this.user_score = 0
          for (let index = 0; index < this.topic.knowledgePointAndScore.length; index++) {
            this.user_score += this.topic.knowledgePointAndScore[index]['score']
            let row = this.topic.knowledgePointAndScore[index]
            if (row.score == null) {
              row.score = 0
            }
          }
        }
      },
      deep: true
    },
    user_score: {
      handler () {
        if (this.topic.user_score == this.user_score) {
          return
        } else {
          this.topic.user_score = this.user_score
        }
      }
    },
    topicIndex: {
      handler () {
        console.log(this.topic)
        this.user_score = this.topic.user_score || 0
        this.$refs.topicRef.scrollTop = 0
        document.getElementsByClassName('view_warp')[0].scrollTop = 0
      }
    }
  },
  props: ['topic', "topicIndex"],
  methods: {
    editUserTopic () {
      if (!this.topic.user_score) {
        this.topic.user_score = 0
      }
      if (this.topic.knowledgePointAndScore && this.topic.knowledgePointAndScore.length == 1) {
        this.topic.knowledgePointAndScore[0].score = this.topic.user_score
      }
      else if (this.topic.knowledgePointAndScore && this.topic.knowledgePointAndScore.length > 1) {
        let knowledgeScore = 0
        this.topic.knowledgePointAndScore.forEach(item => {
          knowledgeScore += item.score
        });
        if (knowledgeScore != this.topic.user_score && this.topic.knowledgePointAndScore.length) {
          this.$message.warning('知识点总分不等于题目总分')
          return
        }
      }
      let data = {
        question_error_id: this.$route.query.id,
        score: this.topic.user_score,
        comment_to_user: this.topic.comment_to_user,
        teacher_comment: this.topic.teacher_comment,
        knowledgePointAndScore: this.topic.knowledgePointAndScore
      }

      this.$http({
        url: '/api/v1/error/paper_detail_edit',
        method: 'post',
        data
      }).then(res => {
        this.$notify({
          title: '提示',
          message: '成功',
          type: 'success'
        });
        this.$router.go(-1)
      })
    },
    eachScore () {
      // 当查看试卷的时候 和 题目未设有知识点的时候 直接提交或切换题目
      if (this.$route.query.isCheck == 1 || this.topic.knowledgePointAndScore.length < 1) {
        return true
      }
      // 只有一个知识点的时候给知识点赋得分
      else if (this.topic.knowledgePointAndScore.length == 1) {
        if (!this.topic.user_score) {
          this.topic.user_score = 0
        }
        this.topic.knowledgePointAndScore[0].score = this.topic.user_score
        return true
      }
      if (!this.topic.user_score) {
        this.topic.user_score = 0
      }

      var score = this.topic.user_score
      var knowledgeScore = 0
      this.topic.knowledgePointAndScore.forEach(item => {
        knowledgeScore += item.score
      });

      if (knowledgeScore > score) {
        this.$message.warning('知识点总得分超过用户所得分')
        return false
      }
      // console.log('knowledgeScore', knowledgeScore)
      // console.log('Score', score)
      if (knowledgeScore != score) {
        this.$message.warning('知识点总得分不等于用户所得分')
        return false
      }
      return true
    },
    add () {
      if (!this.eachScore()) {
        return
      }
      // this.$refs.topicRef.scrollTop = 0
      // document.getElementsByClassName('view_warp')[0].scrollTop = 0
      this.$parent.add()
    },
    sub () {
      if (!this.eachScore()) {
        return
      }
      // document.getElementsByClassName('view_warp')[0].scrollTop = 0
      // this.$refs.topicRef.scrollTop = 0
      this.$parent.sub()
    },
    submitTopic (str = '') {
      if (str == 'err') {
        this.submitErr()
        return
      }
      if (str == 'done') {
        this.$confirm("是否提交整张试卷？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(res => {
          // 提交全部
          this.submit(this.getForm(str))
        })
      } else {
        // 提交单题，存在忽略的情况
        this.submit(this.getForm(str))
      }
    },
    getForm (str) {
      if (str == 'done') {
        var form = {
          submit: str,
          user_paper_id: this.$route.query.id,
          correct_detail: [],
        }

        for (let index = 0; index < this.$parent.topicList.length; index++) {
          let item = this.$parent.topicList[index]


          for (let index = 0; index < item.knowledgePointAndScore.length; index++) {
            let row = item.knowledgePointAndScore[index]
            if (row.score === null) {
              row.score = 0
            }
          }

          var obj = {
            user_paper_detail_id: item.user_paper_detail_id,
            user_score: item.user_score,
            comment_to_user: item.comment_to_user,
            is_ignored: item.is_ignored,
            knowledgePointAndScore: item.knowledgePointAndScore
          }
          form.correct_detail.push(obj)
        }
      } else {
        var form = {
          submit: str,
          user_paper_id: this.$route.query.id,
          correct_detail: [
            {
              user_paper_detail_id: this.topic.user_paper_detail_id,
              user_score: this.topic.user_score,
              comment_to_user: this.topic.comment_to_user,
              is_ignored: this.topic.is_ignored,
              knowledgePointAndScore: this.topic.knowledgePointAndScore
            }
          ],
        }
      }

      return form
    },
    submit (form) {
      if (!this.eachScore()) {
        return
      }
      this.$http({
        url: '/api/v1/papers/correct_done',
        method: 'post',
        data: form
      }).then(res => {
        this.$notify({
          title: '提示',
          message: '成功',
          type: 'success'
        });
        if (form.submit == 'done') {
          this.$refs.evaluateRef.getCommont()
          this.$refs.evaluateRef.dialogVisible = true
          return
        }
        if (form.submit == 'ignore') {
          this.topic.is_ignored == 1
          this.$parent.submitTopic()
          return
        }
        if (form.submit == '') {
          this.topic.is_ignored = 0
          return
        }
        this.$parent.add()
      })
    },
    collect () {
      this.$refs.FormRef.topic = this.topic
      this.$refs.FormRef.dialogVisible = true
    },
    jiucuo () {
      let form = {
        question_id: this.topic.question_id,
        comment: ''
      }
      this.$refs.jiucuoRef.form = form
      this.$refs.jiucuoRef.dialogVisible = true
    }
  }
}
</script>

<style lang="scss" scoped>
// div {
//   scrollbar-width: none; /* Firefox */
//   ::-webkit-scrollbar {
//     display: none;
//   }
// }
.topic_Warp {
  width: 100%;
  height: 100%;
  position: relative;
  box-shadow: 0px 3px 13px 5px rgba(0, 0, 0, 0.05);
  ::v-deep .el-input.is-disabled .el-input__inner {
    color: #000000;
  }
  .topic {
    overflow: auto;
    // height: 74%;
    height: calc(100vh - 320px);
    .header {
      display: flex;
      background: #eff8ff;
      padding: 15px;
      .title {
        flex: 1;
        width: 80px;
        color: #666666;
        .yuan {
          display: inline-block;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          background: #68bcff;
          color: white;
          text-align: center;
          line-height: 30px;
          margin-right: 5px;
        }
        .fenshu {
          display: inline-block;
          font-size: 16px;
          font-weight: bold;
          color: black;
        }
      }
      .collect {
        width: 150px;
        img {
          width: 24px;
          height: 25px;
        }
        span {
          font-size: 20px;
          i {
            margin-right: 5px;
            cursor: pointer;
          }
        }
      }
    }
    .content {
      border: 1px solid e6e6e6;
      margin-bottom: 5px;
      .neirong {
        margin-top: 13px;
        word-break: break-all;
        padding: 5px;
      }
    }
  }
  .evaluate {
    overflow: auto;
    border-top: 1px solid #d9d9d9;
    border-left: 1px solid #d9d9d9;
    margin-top: 10px;
    box-shadow: 0px 3px 13px 5px rgba(0, 0, 0, 0.05);
    position: absolute;
    background: white;
    width: 100%;
    padding-bottom: 30px;
    right: 0;
    min-height: 190px;
    .defen_warp {
      width: 100%;
      display: flex;
      align-items: center;
      margin-top: 2.51%;
      span {
        margin-right: 1.04%;
        display: inline-block;
        width: 100px;
        text-align: center;
        font-size: 16px;
        font-weight: bold;
      }

      .submit_warp {
        display: inline-block;
        text-align: center;
        margin-right: 1.39%;
        flex: 1;
        ::v-deep .el-button--text {
          font-size: 16px;
          font-weight: bold;
        }
      }
    }
    .pingjia_warp {
      display: flex;
      align-items: center;
      margin-top: 2.51%;

      span {
        margin-right: 1.04%;
        display: inline-block;
        width: 112px;
        text-align: center;
        font-size: 16px;
        font-weight: bold;
      }
    }
    .bottom_btn_warp {
      margin-top: 2.51%;
      margin-top: 2.51%;
      .upStage {
        cursor: pointer;
        display: inline-block;
        text-align: center;
        line-height: 44px;
        width: 44px;
        height: 44px;
        background: #3f9eff;
        font-size: 20px;
        border-radius: 50%;
        color: white;
        margin-left: 4%;
        ::v-deep .el-icon-arrow-left {
          font-weight: bold;
        }
      }
      .btn {
        display: inline-block;
        color: white;
        border-radius: 30px;
        font-size: 16px;
        text-align: center;
        line-height: 44px;
        font-weight: bold;
        // padding: 0 30px;
        width: 38%;
        margin-left: 2.5%;
        margin-right: 2.5%;
        height: 44px;
        background: #3f9eff;
        cursor: pointer;
      }
      .submit_all {
        margin: 0 !important;
        text-align: center;
        line-height: 44px;
        display: inline-block;
        background: #fe765f;
      }
    }
  }
}

.warp {
  padding: 5px;
  font-size: 16px;
  text-indent: 1em;
  word-break: break-all;
}
.left_head {
  display: inline-block;
  border-left: 4px solid #3f9eff;
  padding-left: 15px;
  font-weight: bold;
  font-size: 16px;
}
.bottom_border {
  width: 98%;
  border-bottom: 1px solid #e5e4e4;
  height: 15px;
  margin: 0 auto;
}
::v-deep .el-form-item__label {
  color: black;
  font-size: 16px !important;
  font-weight: bold !important;
}

::v-deep .el-col-6 {
  text-align: right;
}
.span_inline_block {
  display: inline-block;
  width: 79px !important;
}
.zhishidian {
  .title {
    margin-top: 2.51%;
    margin-left: 25px;
    font-size: 16px;
    font-weight: bold;
  }
  .item {
    display: flex;
    margin-top: 10px;
    font-size: 16px;
    font-weight: bold;
    span {
      &:nth-child(1) {
        width: 51%;
        display: flex;
        align-items: center;
        margin-left: 25px;
      }
      &:nth-child(2) {
        text-align: center;
      }
    }
  }
}
</style> 